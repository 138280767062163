<script>
	import Base from '@backend/Base.vue';
	import BOGen from '@helper/BOGen';

	export default {
		extends: Base,
		data() {
			return {
				Name: "BoDashboard",
				blogs: {},
				register: {},
				contacts: {},
				data: {},
				site_name: "",
			}
		},
		computed:{
			ufullname(){
				return this.user ? this.user.bu_full_name : "-"
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			refreshData(cb, param = {}) {
				var params = Object.assign(param, this.$route.query)
				this.ajaxRefreshData = BOGen.apirest("/" + this.Name + (this.$route.params.id ? "/" + this.$route.params
					.id : ""), params, (err, resp) => {
					if (err) return;
					this.blogs = resp.blogs
					this.register = resp.register
					this.contacts = resp.contacts
					this.site_name = resp.site_name
					this.PageName = resp.PageName
					this.ObjectName = resp.ObjectName
				})
			},
		}
	};
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>
		<div class="row">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Howdy {{ufullname}}</h5>
						<br> Welcome to the <strong>{{site_name}}</strong> Website Administrator Page 
						<br>You are on the PROTECTED page of the <strong>{{site_name}}</strong> Website Content Management system.<br><br>
						<p> This page is specifically intended ONLY for website administrators who will manage content.<br>
						Before making arrangements, there are few things to note: <br><br>

							1. Save and secure your Username and Password to avoid things that are not desirable.<br>
							2. Prepare the material or content that will be updated first.<br>
							3. Also prepare photos, videos or other related material, to facilitate the updating process.<br>
							4. If there are questions about using features in this backoffice, you can ask the web developer contact below.<br>
							5. Use the latest version of the browser to get the compatibility features in this backoffice.<br>
						<p>
							Web Developer Contact :	lantai21<br>
							Email : richard@lantai21.com<br>
							Phone : 0818 900 754
						</p><br />
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-12" v-if="page.menuRole('AppUserRegister')==true">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">User Register</h5>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>{{fields.u_fullname}}</th>
									<th>{{fields.u_email}}</th>
									<th>{{fields.u_mobile}}</th>
									<th>{{fields.u_status}}</th>
								</tr>
							</thead>
							<tbody v-if="register.length>0">
								<tr v-for="(v,k) in register" :key="k">
									<td class="number">{{k+1}}</td>
									<td>{{v.u_fullname}}</td>
									<td>{{v.email}}</td>
									<td>{{v.mobile}}</td>
									<td v-if="v.u_status == 'N'"><label class="label label-warning">Unverified</label>
									</td>
									<td v-if="v.u_status == 'Y'"><label class="label label-success">Verified</label>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="9" class="text-center"><strong>No content available</strong></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-12" v-if="page.menuRole('AppContactUs')==true">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">New Messages</h5>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="acu_name"></SortField>
									</th>
									<th>{{fields.acu_email}}</th>
									<th>{{fields.acu_message}}</th>
								</tr>
							</thead>
							<tbody v-if="contacts.length>0">
								<tr v-for="(v,k) in contacts" :key="k">
									<td class="number">{{k+1}}</td>
									<td>{{v.acu_name}}</td>
									<td>{{v.acu_email}}</td>
									<td>{{(v.acu_message||'').limitChar(160)}}</td>
								</tr>
								<tr v-if="contacts===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="9" class="text-center"><strong>No content available</strong></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<div class="col-12" v-if="page.menuRole('AppPostBlog')==true">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Latest Blog</h5>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>{{fields.apb_title_en}}</th>
									<th>{{fields.apb_author}}</th>
									<th>{{fields.apb_is_active}}</th>
								</tr>
							</thead>
							<tbody v-if="blogs.length>0">
								<tr v-for="(v,k) in blogs" :key="k">
									<td class="number">{{k+1}}</td>
									<td>{{v.apb_title_en}}</td>
									<td>{{v.apb_author}}</td>
									<td v-if="v.apb_is_active == 'D'"><label class="label label-warning">Draft</label>
									</td>
									<td v-if="v.apb_is_active == 'P'"><label class="label label-success">Publish</label>
									</td>
								</tr>
							</tbody>
							<tbody v-else>
								<tr>
									<td colspan="9" class="text-center"><strong>No content available</strong></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>